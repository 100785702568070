*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

:-moz-focusring {
  outline:  auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

html {
  color: #231f20;
  font-family: Open Sans, "sans-serif";
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.custom-background-intro {
  background-image: url("protein-model.83ab7790.png");
  background-repeat: no-repeat;
  background-size: 45%;
}

@media (min-width: 768px) {
  .custom-background-intro {
    background-size: 40%;
  }
}

@media (min-width: 1280px) {
  .custom-background-intro {
    background-size: 35%;
  }
}

.custom-background-cascade {
  background-image: url("background-cascade.d9429529.png");
  background-repeat: repeat-x;
}

.custom-background-mother-child {
  background-image: url("mother-and-child.2016cdb5.jpg");
  background-position-x: 40%;
  background-size: cover;
}

.card {
  text-align: left;
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

@media (min-width: 768px) {
  .card {
    flex-basis: 50%;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 1280px) {
  .card {
    flex-basis: 25%;
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .card.card-three-up {
    flex-basis: 33.3333%;
  }
}

.card img {
  text-align: left;
  margin-left: 0;
  margin-right: auto;
  display: inline-block;
  position: relative;
}

.card img.needsborder {
  width: 151px;
  height: 151px;
  border-radius: 9999px;
  padding: 11px;
}

.card .name {
  text-align: left;
  margin-top: .75rem;
  font-size: 1.125rem;
  font-weight: 800;
  line-height: 1.375;
}

@media (min-width: 1280px) {
  .card .name {
    line-height: 1.25;
  }
}

.card .title {
  text-align: left;
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.375;
}

@media (min-width: 1280px) {
  .card .title {
    margin-bottom: 1.25rem;
    line-height: 1.25;
  }
}

.card .description {
  text-align: left;
  -webkit-hyphens: auto;
  hyphens: auto;
  -webkit-hyphenate-limit-before: 3;
  -webkit-hyphenate-limit-after: 4;
  -ms-hyphenate-limit-chars: 10 3 4;
  hyphenate-limit-chars: 10 3 4;
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.card .credential {
  font-weight: 400;
}

footer div.grid-container {
  grid-template: "nav nav"
                 "identity identity"
                 "social social"
                 ". compliance"
                 / 2fr 1fr;
  padding: 2.25rem;
  display: grid;
}

footer nav {
  grid-area: nav;
}

footer .identity {
  grid-area: identity;
}

footer .social {
  grid-area: social;
}

footer .compliance {
  grid-area: compliance;
}

.compliance img {
  max-width: 100%;
}

@media (min-width: 768px) {
  footer div.grid-container {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "identity nav"
                         "social compliance";
    display: grid;
  }
}

@media (min-width: 1024px) {
  footer div.grid-container {
    grid-template-columns: max-content max-content 1fr;
    grid-template-areas: "identity nav compliance"
                         "social . .";
    display: grid;
  }

  footer .compliance .seal-container a {
    float: right;
  }
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.top-4 {
  top: 1rem;
}

.right-8 {
  right: 2rem;
}

.left-0 {
  left: 0;
}

.bottom-\[20\%\] {
  bottom: 20%;
}

.-bottom-3 {
  bottom: -.75rem;
}

.-left-12 {
  left: -3rem;
}

.z-20 {
  z-index: 20;
}

.z-50 {
  z-index: 50;
}

.z-10 {
  z-index: 10;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.ml-9 {
  margin-left: 2.25rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-4 {
  margin-top: 1rem;
}

.ml-12 {
  margin-left: 3rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-8 {
  height: 2rem;
}

.h-\[100vh\] {
  height: 100vh;
}

.h-6 {
  height: 1.5rem;
}

.h-12 {
  height: 3rem;
}

.h-screen {
  height: 100vh;
}

.h-full {
  height: 100%;
}

.h-\[70px\] {
  height: 70px;
}

.h-9 {
  height: 2.25rem;
}

.w-screen {
  width: 100vw;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-8 {
  width: 2rem;
}

.w-\[100vw\] {
  width: 100vw;
}

.w-6 {
  width: 1.5rem;
}

.w-12 {
  width: 3rem;
}

.w-1\/2 {
  width: 50%;
}

.w-full {
  width: 100%;
}

.w-9 {
  width: 2.25rem;
}

.w-\[60\%\] {
  width: 60%;
}

.max-w-\[276px\] {
  max-width: 276px;
}

.max-w-xs {
  max-width: 20rem;
}

.basis-1\/4 {
  flex-basis: 25%;
}

.basis-3\/4 {
  flex-basis: 75%;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse) );
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse) );
}

.space-y-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(2.25rem * var(--tw-space-y-reverse) );
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse) );
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.border-2 {
  border-width: 2px;
}

.bg-transparent {
  background-color: rgba(0, 0, 0, 0);
}

.bg-gold {
  --tw-bg-opacity: 1;
  background-color: rgb(254 193 60 / var(--tw-bg-opacity) );
}

.bg-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(38 108 128 / var(--tw-bg-opacity) );
}

.bg-blue-dark\/60 {
  background-color: rgba(19, 34, 42, .6);
}

.bg-lime {
  --tw-bg-opacity: 1;
  background-color: rgb(156 204 98 / var(--tw-bg-opacity) );
}

.bg-turquoise {
  --tw-bg-opacity: 1;
  background-color: rgb(81 188 235 / var(--tw-bg-opacity) );
}

.bg-gray {
  --tw-bg-opacity: 1;
  background-color: rgb(242 242 242 / var(--tw-bg-opacity) );
}

.bg-turquoise-light {
  --tw-bg-opacity: 1;
  background-color: rgb(227 244 252 / var(--tw-bg-opacity) );
}

.bg-\[\#172b43\] {
  --tw-bg-opacity: 1;
  background-color: rgb(23 43 67 / var(--tw-bg-opacity) );
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
}

.from-turquoise {
  --tw-gradient-from: #51bceb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 188, 235, 0));
}

.to-\[\#4295b6\] {
  --tw-gradient-to: #4295b6;
}

.bg-right-bottom {
  background-position: 100% 100%;
}

.bg-left-top {
  background-position: 0 0;
}

.stroke-white {
  stroke: #fff;
}

.object-cover {
  object-fit: cover;
}

.p-1 {
  padding: .25rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pb-9 {
  padding-bottom: 2.25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pt-9 {
  padding-top: 2.25rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-normal {
  font-weight: 400;
}

.uppercase {
  text-transform: uppercase;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-widest {
  letter-spacing: .1em;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-lighttype {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.text-gray {
  --tw-text-opacity: 1;
  color: rgb(242 242 242 / var(--tw-text-opacity) );
}

.text-blue {
  --tw-text-opacity: 1;
  color: rgb(38 108 128 / var(--tw-text-opacity) );
}

.text-turquoise {
  --tw-text-opacity: 1;
  color: rgb(81 188 235 / var(--tw-text-opacity) );
}

.text-gray-dark {
  --tw-text-opacity: 1;
  color: rgb(166 168 171 / var(--tw-text-opacity) );
}

.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.hover\:bg-gold-dark:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 177 11 / var(--tw-bg-opacity) );
}

@media (min-width: 640px) {
  .sm\:container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 640px) {
    .sm\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .sm\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .sm\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .sm\:container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px) {
    .sm\:container {
      max-width: 1536px;
    }
  }

  .sm\:h-\[30rem\] {
    height: 30rem;
  }

  .sm\:min-h-0 {
    min-height: 0;
  }

  .sm\:min-w-0 {
    min-width: 0;
  }

  .sm\:max-w-xl {
    max-width: 36rem;
  }
}

@media (min-width: 768px) {
  .md\:bottom-\[25\%\] {
    bottom: 25%;
  }

  .md\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .md\:ml-\[12\%\] {
    margin-left: 12%;
  }

  .md\:mr-\[12\%\] {
    margin-right: 12%;
  }

  .md\:mt-9 {
    margin-top: 2.25rem;
  }

  .md\:mb-12 {
    margin-bottom: 3rem;
  }

  .md\:mr-12 {
    margin-right: 3rem;
  }

  .md\:mt-6 {
    margin-top: 1.5rem;
  }

  .md\:mt-16 {
    margin-top: 4rem;
  }

  .md\:ml-16 {
    margin-left: 4rem;
  }

  .md\:ml-8 {
    margin-left: 2rem;
  }

  .md\:inline {
    display: inline;
  }

  .md\:flex {
    display: flex;
  }

  .md\:h-\[36rem\] {
    height: 36rem;
  }

  .md\:h-\[100px\] {
    height: 100px;
  }

  .md\:w-\[50\%\] {
    width: 50%;
  }

  .md\:max-w-3xl {
    max-width: 48rem;
  }

  .md\:max-w-2xl {
    max-width: 42rem;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-wrap {
    flex-wrap: wrap;
  }

  .md\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse) );
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse) ) );
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:pl-9 {
    padding-left: 2.25rem;
  }

  .md\:pr-28 {
    padding-right: 7rem;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:leading-snug {
    line-height: 1.375;
  }
}

@media (min-width: 1024px) {
  .lg\:mt-16 {
    margin-top: 4rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline {
    display: inline;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-\[140px\] {
    height: 140px;
  }

  .lg\:basis-1\/5 {
    flex-basis: 20%;
  }

  .lg\:basis-4\/5 {
    flex-basis: 80%;
  }

  .lg\:bg-blue-dark\/40 {
    background-color: rgba(19, 34, 42, .4);
  }

  .lg\:pl-\[4\.5\%\] {
    padding-left: 4.5%;
  }

  .lg\:pl-\[12\%\] {
    padding-left: 12%;
  }

  .lg\:pr-\[25\%\] {
    padding-right: 25%;
  }
}

@media (min-width: 1280px) {
  .xl\:bottom-\[15\%\] {
    bottom: 15%;
  }

  .xl\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .xl\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .xl\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .xl\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .xl\:mb-16 {
    margin-bottom: 4rem;
  }

  .xl\:mb-12 {
    margin-bottom: 3rem;
  }

  .xl\:mt-12 {
    margin-top: 3rem;
  }

  .xl\:mt-20 {
    margin-top: 5rem;
  }

  .xl\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .xl\:inline {
    display: inline;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:h-\[170px\] {
    height: 170px;
  }

  .xl\:w-\[276px\] {
    width: 276px;
  }

  .xl\:min-w-0 {
    min-width: 0;
  }

  .xl\:max-w-5xl {
    max-width: 64rem;
  }

  .xl\:max-w-2xl {
    max-width: 42rem;
  }

  .xl\:max-w-7xl {
    max-width: 80rem;
  }

  .xl\:max-w-\[27rem\] {
    max-width: 27rem;
  }

  .xl\:basis-1\/2 {
    flex-basis: 50%;
  }

  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:items-center {
    align-items: center;
  }

  .xl\:justify-center {
    justify-content: center;
  }

  .xl\:p-3 {
    padding: .75rem;
  }

  .xl\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .xl\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .xl\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .xl\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .xl\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .xl\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .xl\:pb-12 {
    padding-bottom: 3rem;
  }

  .xl\:pl-28 {
    padding-left: 7rem;
  }

  .xl\:pr-16 {
    padding-right: 4rem;
  }

  .xl\:pr-20 {
    padding-right: 5rem;
  }

  .xl\:pt-12 {
    padding-top: 3rem;
  }

  .xl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .xl\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .xl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .xl\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .xl\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .xl\:leading-tight {
    line-height: 1.25;
  }

  .xl\:leading-snug {
    line-height: 1.375;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .\32 xl\:mt-16 {
    margin-top: 4rem;
  }

  .\32 xl\:inline {
    display: inline;
  }

  .\32 xl\:h-\[48rem\] {
    height: 48rem;
  }

  .\32 xl\:h-\[200px\] {
    height: 200px;
  }

  .\32 xl\:max-w-6xl {
    max-width: 72rem;
  }

  .\32 xl\:max-w-4xl {
    max-width: 56rem;
  }

  .\32 xl\:max-w-7xl {
    max-width: 80rem;
  }

  .\32 xl\:scroll-py-28 {
    scroll-padding-top: 7rem;
    scroll-padding-bottom: 7rem;
  }

  .\32 xl\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .\32 xl\:pr-16 {
    padding-right: 4rem;
  }

  .\32 xl\:pl-14 {
    padding-left: 3.5rem;
  }

  .\32 xl\:pr-12 {
    padding-right: 3rem;
  }

  .\32 xl\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .\32 xl\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .\32 xl\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .\32 xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .\32 xl\:text-\[3\.5rem\] {
    font-size: 3.5rem;
  }

  .\32 xl\:leading-tight {
    line-height: 1.25;
  }

  .\32 xl\:leading-normal {
    line-height: 1.5;
  }

  .\32 xl\:leading-\[1\.25em\] {
    line-height: 1.25em;
  }
}

/*# sourceMappingURL=index.5b8981cc.css.map */
