@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Open Sans", "sans-serif";
    color: #231f20;
  }
}

@layer components {
  /* 
   * backgrounds 
   */
  .custom-background-intro {
    background-image: url("../img/protein-model.png");
    /* background-size: 42%; */
    background-size: 45%;
    background-repeat: no-repeat;
  }

  @media screen(md) {
    .custom-background-intro {
      background-size: 40%;
    }
  }

  @media screen(xl) {
    .custom-background-intro {
      background-size: 35%;
    }
  }

  .custom-background-cascade {
    background-image: url("../img/background-cascade.png");
    background-repeat: repeat-x;
  }

  .custom-background-mother-child {
    background-image: url("../img/backgrounds/mother-and-child.jpg");
    background-size: cover;
    background-position-x: 40%;
  }
  /* this was used in footer, but now turned off */
  .custom-background-starry {
    background-image: url("../img/backgrounds/protein-model-on-dark-field.jpg");
    background-size: cover;
    background-position-x: 50%;
  }

  @media screen(lg) {
    .custom-background-starry {
      background-position-x: 75%;
    }
  }

  @media screen(xl) {
    .custom-background-starry {
      background-position-x: 100%;
    }
  }

  /*
   * management section
   */
  .card {
    @apply my-9 md:my-6 xl:my-9 xl:text-sm text-left md:basis-1/2 md:px-6 xl:basis-1/4;
  }
  .card.card-three-up {
    @apply xl:basis-1/3;
  }
  .card img {
    @apply inline-block ml-0 mr-auto text-left relative;
  }
  .card img.needsborder {
    padding: 11px;
    width: 151px;
    height: 151px;
    @apply rounded-full;
  }
  .card .name {
    @apply mt-3 text-lg leading-snug xl:leading-tight font-extrabold text-left;
  }
  .card .title {
    @apply mb-6 xl:mb-5 text-lg xl:leading-tight leading-snug font-normal text-left;
  }
  .card .description {
    @apply my-3 text-left;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;

    -webkit-hyphenate-limit-before: 3;
    -webkit-hyphenate-limit-after: 4;
    -ms-hyphenate-limit-chars: 10 3 4;
    hyphenate-limit-chars: 10 3 4;
  }

  .card .credential {
    @apply font-normal;
  }
  /*
   * footer
   */
  footer div.grid-container {
    @apply p-9;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "nav nav"
      "identity identity"
      "social social"
      ". compliance";
  }
  footer nav {
    grid-area: nav;
    /* @apply border-lime border-2; */
  }
  footer .identity {
    /*@apply border-2 border-turquoise; */
    grid-area: identity;
  }
  footer .social {
    /* @apply border-2 border-blue; */
    grid-area: social;
  }
  footer .compliance {
    /* @apply border-2 border-turquoise; */
    grid-area: compliance;
  }
  .compliance img {
    max-width: 100%;
  }
  @media screen(md) {
    footer div.grid-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "identity nav"
        "social compliance";
    }
  }
  @media screen(lg) {
    footer div.grid-container {
      display: grid;
      grid-template-columns: max-content max-content 1fr;
      grid-template-areas:
        "identity nav compliance"
        "social . . ";
    }
    footer .compliance .seal-container a {
      float: right;
    }
  }
}
